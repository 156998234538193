import { AppEnvType } from './types';

const isSslEndpoint = (endpoint: string) => {
    return endpoint.startsWith('https://');
};

const isDevelopmentEndpoint = (endpoint: string) => {
    return endpoint.includes('dev-api.sandbox') || endpoint.includes('localhost') || endpoint.includes('uat-api.uat');
};

const getAppEnv = (): AppEnvType => {
    const environment = process.env.NODE_ENV;
    if (!environment) {
        throw new Error('Environment not set');
    }
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    if (!API_ENDPOINT) {
        throw new Error('API_ENDPOINT not set');
    }

    if (!isSslEndpoint(API_ENDPOINT)) {
        throw new Error('API_ENDPOINT is not an SSL endpoint');
    }

    if (environment === 'development' && !isDevelopmentEndpoint(API_ENDPOINT)) {
        throw new Error('API_ENDPOINT is not a development endpoint');
    }

    const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
    if (!FIREBASE_APIKEY) {
        throw new Error('FIREBASE_APIKEY not set');
    }
    const FIREBASE_AUTHDOMAIN = process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
    if (!FIREBASE_AUTHDOMAIN) {
        throw new Error('FIREBASE_AUTHDOMAIN not set');
    }
    const FIREBASE_PROJECTID = process.env.REACT_APP_FIREBASE_PROJECTID;
    if (!FIREBASE_PROJECTID) {
        throw new Error('FIREBASE_PROJECTID not set');
    }
    const FIREBASE_STORAGEBUCKET = process.env.REACT_APP_FIREBASE_STORAGEBUCKET;
    if (!FIREBASE_STORAGEBUCKET) {
        throw new Error('FIREBASE_STORAGEBUCKET not set');
    }
    const FIREBASE_MESSAGINGSENDERID = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
    if (!FIREBASE_MESSAGINGSENDERID) {
        throw new Error('FIREBASE_MESSAGINGSENDERID not set');
    }
    const FIREBASE_APPID = process.env.REACT_APP_FIREBASE_APPID;
    if (!FIREBASE_APPID) {
        throw new Error('FIREBASE_APPID not set');
    }

    const FIREBASE_MESSAGING_KEY = process.env.REACT_APP_FIREBASE_MESSAGING_KEY;
    if (!FIREBASE_MESSAGING_KEY) {
        throw new Error('FIREBASE_MESSAGING_KEY not set');
    }

    const FIREBASE_PROVIDER_GOOGLE_CLIENTID = process.env.REACT_APP_FIREBASE_PROVIDER_GOOGLE_CLIENTID;
    if (!FIREBASE_PROVIDER_GOOGLE_CLIENTID) {
        throw new Error('FIREBASE_PROVIDER_GOOGLE_CLIENTID not set');
    }

    const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
    if (!FACEBOOK_APP_ID) {
        throw new Error('FACEBOOK_APP_ID not set');
    }

    const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
    if (!APPLE_CLIENT_ID) {
        throw new Error('APPLE_CLIENT_ID not set');
    }

    const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;

    return {
        environment,
        API_ENDPOINT,
        FIREBASE_APIKEY,
        FIREBASE_AUTHDOMAIN,
        FIREBASE_PROJECTID,
        FIREBASE_STORAGEBUCKET,
        FIREBASE_MESSAGINGSENDERID,
        FIREBASE_APPID,
        FIREBASE_PROVIDER_GOOGLE_CLIENTID,
        FIREBASE_MESSAGING_KEY,
        FACEBOOK_APP_ID,
        APPLE_CLIENT_ID,
        APPLE_REDIRECT_URI,
    };
};

export const AppEnv = getAppEnv();
