import React from 'react';
import { authHandler } from './useAuth';
import { auth } from '../firebase/Firebase';

export const registerRedirectResultHandler = (handler: authHandler) => {
    const { getRedirectResult } = handler;

    React.useEffect(() => {
        getRedirectResult(auth).catch((err) => console.error(err));
    }, []);
};
