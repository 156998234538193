import { configureStore } from '@reduxjs/toolkit';
import { AuthReducer } from './components/auth/reducer';
import { CustomFirebaseUiReducer } from './components/custom-firebase-ui/reducer';

export const store = configureStore({
    reducer: {
        custom_firebase_ui: CustomFirebaseUiReducer,
        auth: AuthReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ['auth.firebaseUser'],
                ignoredActions: ['Auth/setFirebaseUser'],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
