import { GoogleAuthProvider } from 'firebase/auth';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { authHandler } from './useAuth';
import { auth, SETTINGS_PROVIDER_GOOGLE } from '../firebase';

export const registerOneTap = (handler: authHandler) => {
    const { authenticated, signInWithCredential } = handler;

    useGoogleOneTapLogin({
        disabled: !!authenticated,
        onError: (error) => {
            console.error(error);
        },
        googleAccountConfigs: {
            callback: async (res: any) => {
                const credential = GoogleAuthProvider.credential(res.credential);
                try {
                    await signInWithCredential(auth, credential);
                } catch (error) {
                    console.error(error);
                }
            },
            client_id: SETTINGS_PROVIDER_GOOGLE.clientId,
        },
    });
};
