import { useEffect } from 'react';
import { authHandler } from '.';
import { useAccessTokenHandler } from './useAccessTokenHandler';
import { auth } from '../firebase';

export const registerAccessTokenHandler = (handler: authHandler) => {
    const { getAccessTokenResult } = handler;
    const { params: accessTokenParams } = useAccessTokenHandler();

    useEffect(() => {
        if (accessTokenParams) {
            getAccessTokenResult(auth, accessTokenParams).catch((err) => console.error(err));
        }
    }, [accessTokenParams]);
};
