import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type RedirectState = {
    type: 'facebook' | 'google' | 'apple';
    ct: string;
    status: 'request' | 'ready_for_signin';
    pathOverride?: string;
    searchOverride?: string;
};

export type AccessTokenParams = {
    accessToken: string;
    redirectState: RedirectState;
    validCsrf: boolean;
    results: FragmentResults | undefined;
};

export type AppleUser = {
    email: string;
    name: {
        firstName: string;
        lastName: string;
    };
};

export type FragmentResults =
    | {
          type: 'apple';
          idToken: string | null;
          user: AppleUser | null;
      }
    | {
          type: 'google' | 'facebook';
          accessToken: string | null;
      };

const useFragment = () => {
    const location = useLocation();
    const { hash } = location;
    let _hash = hash;
    if (hash) {
        _hash = '?' + hash.slice(1, hash.length);
    }
    return React.useMemo(() => new URLSearchParams(_hash), [_hash]);
};

export const useAccessTokenHandler = () => {
    const fragment = useFragment();

    const getState = () => {
        try {
            const _state = fragment.get('state');
            const state: RedirectState | undefined = _state ? JSON.parse(_state) : undefined;
            // proper data check
            return state;
        } catch (error) {
            console.error(error);
        }
        return undefined;
    };
    const redirectState = useMemo(getState, [fragment]);

    const getAccessToken = () => {
        if (redirectState?.type === 'apple') {
            return fragment.get('id_token');
        }
        return fragment.get('access_token');
    };

    const getResults = (): FragmentResults | undefined => {
        if (redirectState) {
            if (redirectState.type === 'apple') {
                const userString = fragment.get('user');
                const user = userString ? (JSON.parse(userString) as AppleUser) : null;
                return {
                    type: 'apple',
                    idToken: fragment.get('id_token'),
                    user,
                };
            } else {
                return {
                    type: redirectState.type,
                    accessToken: fragment.get('access_token'),
                };
            }
        }
        return undefined;
    };

    const validateCsrf = () => {
        return redirectState && redirectState.ct === 'testing1234';
    };

    const getParams = (): AccessTokenParams | undefined => {
        const accessToken = getAccessToken();
        const results = getResults();
        const validCsrf = validateCsrf();
        if (accessToken && redirectState && validCsrf) {
            return {
                accessToken,
                results,
                redirectState,
                validCsrf,
            };
        }

        return undefined;
    };
    const params = useMemo(getParams, [fragment]);

    return {
        params,
    };
};
