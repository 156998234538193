const rosterfyColors = {
    blue: '#3131C7',
    medium_blue: '#5271FF',
    light_blue: '#38B6FF',
    dark_purple: '#8C52FF',
    light_purple: '#CB6CE6',
    light_green: '#22D19D',
    dark_grey: '#545454',
    medium_grey: '#737373',
    grey: '#A6A6A6',
    light_grey: '#D9D9D9',
    black: '#000000',
    white: '#FFFFFF',
};

export const rosterfyPalette = {
    primary: rosterfyColors.blue,
    ...rosterfyColors,
};

export const rosterfyConnectPalette = {
    primary: rosterfyColors.light_blue,
    ...rosterfyColors,
};

export const rosterfyCommunityPalette = {
    primary: rosterfyColors.light_purple,
    ...rosterfyColors,
};

export const rosterfyBgGradient = `linear(to-br, ${rosterfyPalette.primary}, ${rosterfyPalette.light_blue})`;
