import { Button, Card, CardBody, CardHeader, FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { CommunitySearchApi } from '../../community-api/search/api';

export const SearchTest: FC = () => {
    const [search, setSearch] = useState<string>('');
    const [locale, setLocale] = useState<string>('en-US');
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<string>();

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!search) {
            return;
        }
        setSearchLoading(true);
        try {
            const res = await CommunitySearchApi.SearchOpportunity(search, { locale });
            setSearchResults(JSON.stringify(res.data, null, 2));
            console.log(res);
        } catch (error) {}
        setSearchLoading(false);
    };

    const doDebug = (params: Parameters<typeof CommunitySearchApi.DebugOpp>[0]) => {
        return async () => {
            try {
                const res = await CommunitySearchApi.DebugOpp(params);
                console.log(res);
            } catch (error) {}
        };
    };

    return (
        <Stack spacing="1">
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Search opportunities
                    </Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={onSubmit}>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Search Text</FormLabel>
                                <Input
                                    name="search"
                                    type="input"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Locale</FormLabel>
                                <Input
                                    name="locale"
                                    type="input"
                                    value={locale}
                                    onChange={(e) => setLocale(e.target.value)}
                                />
                            </FormControl>

                            <Stack direction="row" spacing={1}>
                                <Button type="submit" isLoading={searchLoading}>
                                    Search
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Search test
                    </Heading>
                </CardHeader>
                <CardBody>{searchResults}</CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        mapping_test
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Button
                        type="button"
                        onClick={doDebug({
                            mapping_test: 'enabled',
                        })}
                    >
                        Debug
                    </Button>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        geo_filtering_test
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Button
                        type="button"
                        onClick={doDebug({
                            geo_filtering_test: 'enabled',
                            locale: 'en-US',
                        })}
                    >
                        Debug
                    </Button>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        delete_index
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Button
                        type="button"
                        onClick={async () => {
                            if (window.confirm('Are you sure?')) {
                                try {
                                    await doDebug({
                                        delete_index: 'enabled',
                                    })();
                                } catch (error) {
                                    console.error(error);
                                }
                            }
                        }}
                    >
                        Debug
                    </Button>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        dynamic_template
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Button
                        type="button"
                        onClick={doDebug({
                            dynamic_template: 'enabled',
                        })}
                    >
                        Debug
                    </Button>
                </CardBody>
            </Card>
        </Stack>
    );
};
