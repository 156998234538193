import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { getIdTokenResult } from 'firebase/auth';
import { attachCustomTokenToCallback } from './attachToCallback';
import { AuthActions } from './reducer';
import { AuthConfig, useAuth } from './useAuth';
import { CommunityAuthApi } from '../../community-api/auth/api';
import { ROUTE_REGISTRATION } from '../../constants';
import { auth } from '../firebase';

const { CustomTokenWithIdToken } = CommunityAuthApi;

const idTokenManager = {
    use: (token: string) => {
        axios.defaults.headers.common.Authorization = 'Bearer ' + token;
        axios.defaults.withCredentials = false;
    },
    stopUseAndClear: () => {
        axios.defaults.headers.common.Authorization = undefined;
        axios.defaults.withCredentials = false;
    },
};

const COMMUNITY_AUTH_CONFIG: AuthConfig = {
    beforeInitialise: async ({ dispatch }) => {
        dispatch(AuthActions.setIsLoading(true));
    },
    initialise: async ({ dispatch, loadAuthenticated }) => {
        try {
            if (auth.currentUser) {
                const res = await getIdTokenResult(auth.currentUser);
                idTokenManager.use(res.token);
                await loadAuthenticated();
            }
        } catch (error) {
            console.error(error);
        }
        dispatch(AuthActions.setIsLoading(false));
    },
    beforeLogin: async ({ dispatch }, action) => {
        dispatch(AuthActions.setLoadingOnAction({ value: true, method: action.method }));
    },
    login: async ({ loadAuthenticated, dispatch, navigate }, credential, action) => {
        const { user } = credential;
        const res = await getIdTokenResult(user);
        idTokenManager.use(res.token);
        try {
            await loadAuthenticated();
        } catch (error: any) {
            dispatch(AuthActions.setNoAuthenticated());
            const redirectToRegistration = error.response?.status === 404;
            if (redirectToRegistration) {
                navigate(ROUTE_REGISTRATION);
            } else {
                console.error(error);
            }
        }
        dispatch(AuthActions.setLoadingOnAction({ value: false, method: action.method }));
    },
    loginError: async ({ dispatch }, error, action) => {
        dispatch(AuthActions.setLoadingOnAction({ value: false, method: action.method }));
        if (error instanceof FirebaseError) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                if ((error?.customData?._tokenResponse as Record<string, any>).verifiedProvider) {
                    const verifiedProviders = (error?.customData?._tokenResponse as Record<string, any>)
                        .verifiedProvider as string[];
                    dispatch(
                        AuthActions.setAlert({
                            type: 'firebase/auth/account-exists-with-different-credential',
                            state: {
                                verifiedProviders,
                            },
                        })
                    );
                } else {
                    dispatch(
                        AuthActions.setAlert({
                            type: 'firebase/auth/account-exists-with-different-credential',
                            state: {
                                verifiedProviders: undefined,
                            },
                        })
                    );
                }
            }
        }
    },
    loginNoResult: async ({ dispatch }, action) => {
        dispatch(AuthActions.setLoadingOnAction({ value: false, method: action.method }));
    },
    loginWithCallback: async ({ dispatch }, callbackUrl, credential) => {
        const { user } = credential;
        dispatch(AuthActions.setLoginCallbackLoading(true));
        try {
            const res = await getIdTokenResult(user);
            const {
                data: { customToken },
            } = await CustomTokenWithIdToken(res.token);
            const url = attachCustomTokenToCallback(callbackUrl, customToken);
            window.location.href = url;
        } catch (error) {
            dispatch(AuthActions.setLoginCallbackLoading(false));
            throw error;
        }
    },
    signout: async ({ dispatch }) => {
        idTokenManager.stopUseAndClear();
        dispatch(AuthActions.setNoAuthenticated());
    },
    authenticated: async ({ dispatch }, me) => {
        dispatch(AuthActions.setAuthenticated(me));
    },
    idTokenChanged: async (_, user) => {
        if (user) {
            const res = await getIdTokenResult(user);
            idTokenManager.use(res.token);
        }
    },
    afterRefreshIdToken: async (_, idTokenResult) => {
        idTokenManager.use(idTokenResult.token);
    },
};

export const useCommunityAuth = () => {
    return useAuth(COMMUNITY_AUTH_CONFIG);
};
