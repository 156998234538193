import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginAction } from './useAuth';
import { CommunityUser } from '../../community-api/users/types';

export type AuthAlert = {
    type: 'firebase/auth/account-exists-with-different-credential';
    state: {
        verifiedProviders?: string[];
    };
};

export type AuthState = {
    ready: boolean;
    authenticated: CommunityUser.UserMeResponse | null | undefined;
    isLoading: boolean;
    redirectResultLoading: boolean;
    accessTokenLoading: boolean;
    loginCallbackLoading: boolean;
    alert: AuthAlert | undefined;
};

const initialState: AuthState = {
    ready: false,
    authenticated: undefined,
    isLoading: false,
    redirectResultLoading: false,
    accessTokenLoading: false,
    loginCallbackLoading: false,
    alert: undefined,
};

const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setReady: (state, action: PayloadAction<AuthState['ready']>) => {
            state.ready = action.payload;
        },
        setAuthenticated: (state, action: PayloadAction<AuthState['authenticated']>) => {
            state.authenticated = action.payload;
        },
        setNoAuthenticated: (state) => {
            state.authenticated = null;
        },
        setIsLoading: (state, action: PayloadAction<AuthState['isLoading']>) => {
            state.isLoading = action.payload;
        },
        setLoginCallbackLoading: (state, action: PayloadAction<AuthState['loginCallbackLoading']>) => {
            state.loginCallbackLoading = action.payload;
        },
        setLoadingOnAction: (state, action: PayloadAction<{ value: boolean; method?: LoginAction['method'] }>) => {
            const { method, value } = action.payload;
            if (method === 'getAccessTokenResult') {
                state.accessTokenLoading = value;
            } else if (method === 'getRedirectResult') {
                state.redirectResultLoading = value;
            } else {
                state.isLoading = value;
            }
        },
        setAlert: (state, action: PayloadAction<AuthState['alert']>) => {
            state.alert = action.payload;
        },
        closeAlert: (state) => {
            state.alert = undefined;
        },
    },
});

export const AuthReducer = AuthSlice.reducer;
export const AuthActions = AuthSlice.actions;
