import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CommunityAccountApi } from '../community-api/accounts/api';
import { Spinner } from '../styles/Spinner';

export const AccountLinkProcess: FC = () => {
    let { code } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const process = async () => {
            if (code) {
                await CommunityAccountApi.PostAccountLinkProcess({ code });
            }
            return undefined;
        };

        process()
            .then(() => {
                setTimeout(() => {
                    navigate({
                        pathname: '/',
                        search: location.search,
                    });
                }, 1000);
            })
            .catch((err) => console.error(err));
    }, []);
    return (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    );
};
