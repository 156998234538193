import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationDefaultLang, translationResources } from './config';

i18next
    .use(initReactI18next)
    // .use(languageDetector)
    .init({
        resources: translationResources,
        lng: translationDefaultLang,
        debug: false,
        fallbackLng: translationDefaultLang,
        // ns: nsList,
        // defaultNS: nsList[0],
        compatibilityJSON: 'v3',
        partialBundledLanguages: true,
    })
    .catch((err) => {
        console.log(err);
    });

export * from './config';
export * from './resources';
export * from './TranslationTest';
export * from './useRosterfyTranslation';
export * from './utils';
