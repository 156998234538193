import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AccountLinkTest } from './components/accounts';
import { registerAccessTokenHandler } from './components/auth';
import { registerAuth } from './components/auth/registerAuth';
import { useCommunityAuth } from './components/auth/useCommunityAuth';
import { PushTest } from './components/push-notifications';
import { SearchTest } from './components/search';
import { TranslationTest } from './components/translations';
import { ROUTE_REGISTRATION } from './constants';
import { AccountLinkProcess } from './pages/AccountLinkProcess';
import { Auth } from './pages/Auth';
import { MeTest } from './pages/MeTest';
import { Registration } from './pages/Registration';
import { VerifyOtlc } from './pages/VerifyOtlc';

// 1
export const Community: FC = () => {
    const authHandler = useCommunityAuth();
    const { authenticated } = authHandler;
    registerAuth(authHandler);
    registerAccessTokenHandler(authHandler);

    return (
        <Box maxW="1024px" mx="auto" p="0.5rem">
            <Routes>
                <Route index element={authenticated ? <MeTest /> : <Auth />} />
                <Route path="/login" element={<Auth />} />
                <Route path="/auth/verify_otlc/:code" element={<VerifyOtlc />} />
                <Route path={ROUTE_REGISTRATION} element={<Registration />} />
                <Route path="/push_test" element={authenticated ? <PushTest /> : <Auth />} />
                <Route path="/translation_test" element={authenticated ? <TranslationTest /> : <Auth />} />
                <Route path="/search_test" element={authenticated ? <SearchTest /> : <Auth />} />
                <Route path="/account_linking_test" element={authenticated ? <AccountLinkTest /> : <Auth />} />
                <Route path="/main/account_link/:code" element={authenticated ? <AccountLinkProcess /> : <Auth />} />
            </Routes>
        </Box>
    );
};
