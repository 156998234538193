import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react';
import { FC, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { AuthActions } from '../auth/reducer';

export const AuthAlert: FC = () => {
    const dispatch = useAppDispatch();
    const alert = useAppSelector((state) => state.auth.alert);
    const cancelRef = useRef(null);

    const onClose = () => {
        dispatch(AuthActions.closeAlert());
    };

    const verifiedProviders =
        alert?.state.verifiedProviders && alert?.state.verifiedProviders.length
            ? alert?.state.verifiedProviders
            : undefined;

    return alert ? (
        <>
            <AlertDialog
                isOpen={alert && alert.type === 'firebase/auth/account-exists-with-different-credential'}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Email already registered
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text mb="1rem">
                                You have already registered with this email from another provider.{' '}
                                {verifiedProviders
                                    ? `Please sign in
                                with a verified provider or by email link.`
                                    : `Please sign in by email link.`}
                            </Text>
                            {verifiedProviders ? (
                                <>
                                    <Text fontWeight="bold">Verified Providers:</Text>
                                    <UnorderedList>
                                        {verifiedProviders.map((provider) => (
                                            <ListItem key={provider}>{provider}</ListItem>
                                        ))}
                                    </UnorderedList>
                                </>
                            ) : undefined}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button colorScheme="blue" onClick={onClose} ml={3}>
                                Ok
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    ) : (
        <></>
    );
};
