import axios from 'axios';
import { CommunityAuth } from './types';
import { CommunityEndpoint } from '..';
import { AppleUser } from '../../components/auth';

const LoginEmail = (params: CommunityAuth.LoginEmailParams) => {
    const { email } = params;
    return axios.post<CommunityAuth.LoginEmailResponse>(`${CommunityEndpoint}/auth/login_email`, {
        email,
    });
};

const CustomToken = (params: CommunityAuth.CustomTokenParams) => {
    const { code } = params;
    return axios.post<CommunityAuth.CustomTokenResponse>(`${CommunityEndpoint}/auth/custom_token`, {
        code,
    });
};

const CustomTokenWithIdToken = (idToken: string) => {
    return axios.get<CommunityAuth.CustomTokenWithIdTokenResponse>(`${CommunityEndpoint}/auth/custom_token`, {
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
    });
};

const SaveAppleLoginDetails = (request: AppleUser, idToken?: string) => {
    if (idToken) {
        return axios.post<CommunityAuth.SaveAppleLoginDetails>(
            `${CommunityEndpoint}/auth/apple_login_details`,
            request,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            }
        );
    }
    return axios.post<CommunityAuth.SaveAppleLoginDetails>(`${CommunityEndpoint}/auth/apple_login_details`, request);
};

export const CommunityAuthApi = {
    LoginEmail,
    CustomToken,
    CustomTokenWithIdToken,
    SaveAppleLoginDetails,
};
