import platform from 'platform';

const APP_URL_SCHEME = 'rosterfyapp://';
const VERIFY_PATH = 'auth/verify_otlc/';

export const useRedirectToApp = () => {
    const isMobile = {
        android: () => {
            return platform.os?.family === 'Android';
        },
        ios: () => {
            return platform.os?.family === 'iOS';
        },
        any: () => {
            return isMobile.android() || isMobile.ios();
        },
    };

    const redirectToApp = (code: string) => {
        if (isMobile.any()) {
            const deeplink = APP_URL_SCHEME + VERIFY_PATH + code;
            window.location.href = deeplink;
        }
    };

    return { redirectToApp, isMobile };
};
