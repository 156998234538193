import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    useToast,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { CustomFirebaseUiActions } from './reducer';
import { useAppDispatch } from '../../hooks';
import { useCommunityAuth } from '../auth';
import { auth } from '../firebase';

export const LoginEmailPasswordPanel: FC = () => {
    const { signInWithEmailAndPassword } = useCommunityAuth();
    const dispatch = useAppDispatch();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const toast = useToast();

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (email) {
            setIsLoading(true);
            try {
                await signInWithEmailAndPassword(auth, email, password);
                dispatch(CustomFirebaseUiActions.setView(undefined));
            } catch (error: any) {
                console.error(error);
                toast({
                    title: 'Invalid credentials',
                    status: 'error',
                    duration: 9000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
            setIsLoading(false);
        }
    };

    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <Box>
            <Box as="form" onSubmit={onSubmit}>
                <Card>
                    <CardHeader>
                        <Heading size="md">Sign in with email &amp; password</Heading>
                    </CardHeader>
                    <CardBody>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    autoFocus={true}
                                    type="email"
                                    name="email"
                                    value={email}
                                    required={true}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={password}
                                        required={true}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={togglePassword}>
                                            {showPassword ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                        </Stack>
                    </CardBody>
                    <CardFooter>
                        <Stack direction="row" spacing={2}>
                            <Button
                                type="button"
                                onClick={() => {
                                    dispatch(CustomFirebaseUiActions.setView(undefined));
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                                Login
                            </Button>
                        </Stack>
                    </CardFooter>
                </Card>
            </Box>
        </Box>
    );
};
