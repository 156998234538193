import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth';
import React from 'react';
import { authHandler } from './useAuth';
import { auth } from '../firebase';

export const registerAuth = (handler: authHandler) => {
    const { beforeInitialise, handleIdTokenChanged, handleAuthStateChanged } = handler;

    React.useEffect(() => {
        beforeInitialise().catch((err) => console.error(err));
        const unsubscribeAuthStateChanged = onAuthStateChanged(auth, handleAuthStateChanged);
        const unsubscribeIdTokenChanged = onIdTokenChanged(auth, handleIdTokenChanged);
        return () => {
            unsubscribeAuthStateChanged();
            unsubscribeIdTokenChanged();
        };
    }, []);
};
