import axios from 'axios';
import { CommunityAccount } from './types';
import { CommunityEndpoint } from '..';

const PostAccountLink = (form: CommunityAccount.AccountLinkRequest) => {
    return axios.post<CommunityAccount.AccountLinkResponse>(`${CommunityEndpoint}/account/link`, form);
};

const PostAccountLinkProcess = (form: CommunityAccount.AccountLinkProcessRequest) => {
    return axios.post<CommunityAccount.AccountLinkProcessResponse>(`${CommunityEndpoint}/account/link_process`, form);
};

export const CommunityAccountApi = {
    PostAccountLink,
    PostAccountLinkProcess,
};
