import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    FormControl,
    FormLabel,
    Heading,
    Input,
    List,
    Stack,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { CommunityPushApi } from '../../community-api/push/api';
import { CommunityPush } from '../../community-api/push/types';
import { registerPush, useNotificationsPagination, usePush } from '../push';

const testers = ['community_a', 'community_create_group', 'community_add_to_group', 'community_load_test'] as const;

const TEST_IMAGE_URL = 'https://picsum.photos/132/132';

export const PushTest: FC = () => {
    const push = usePush();
    registerPush(push);
    const {
        regToken,
        createRegToken,
        deleteRegToken,
        isLoading: pushLoading,
        isEnabled,
        isSupported,
        lastMessage,
        lastMessageTime,
        getNotificationsPaginated,
        deleteNotification,
    } = push;
    const [testToken, setTestToken] = useState<string>('');
    const [accountRrn, setAccountRrn] = useState<string>();
    const [subtitle, setSubtitle] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState('Test Notification');
    const [body, setBody] = useState('Hello universe!');
    const [icon, setIcon] = useState<string>('https://www.rosterfy.com/hubfs/Rosterfy%20Favicon%20(1).png');
    const [image, setImage] = useState<string>(TEST_IMAGE_URL);
    const [imageIsCircular, setImageIsCircular] = useState(false);
    const [channel, setChannel] = useState<string>();
    const [actionScheme, setActionScheme] = useState(false);
    const [highPriority, setHighPriority] = useState(false);
    const [testLoading, setTestLoading] = useState(false);
    const [testResponse, setTestResponse] = useState('');
    const [notifications, setNotifications] = useState<CommunityPush.NotificationRecord[]>([]);
    const [deleting, setDeleting] = useState(false);
    const { prevIndex, currIndex, nextIndex, nextPage, prevPage, isLast, appendHistory } = useNotificationsPagination();

    useEffect(() => {
        getNotificationsPaginated({
            startIndex: currIndex,
        })
            .then((res) => {
                const { items, nextToken: _nextIndex } = res.data;
                setNotifications(items);
                if (isLast) {
                    appendHistory(_nextIndex);
                }
            })
            .catch((err) => console.error(err));
    }, [currIndex]);

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!regToken) {
            return;
        }
        setTestLoading(true);
        try {
            const res = await CommunityPushApi.CommunityTest({
                regToken: testToken.length ? testToken : undefined,
                message_community: {
                    vmsAccountRrn: accountRrn && accountRrn.length ? accountRrn : undefined,
                    subtitle,
                    title,
                    body,
                    icon,
                    image: image
                        ? {
                              image,
                              isCircular: imageIsCircular,
                          }
                        : undefined,
                    channel,
                    actionScheme: actionScheme
                        ? {
                              scheme: 'test_action',
                          }
                        : undefined,
                    priority: highPriority ? 'high' : undefined,
                },
            });
            setTestResponse(JSON.stringify(res.data));
        } catch (error) {}
        setTestLoading(false);
    };

    const sendTestMessage = (mode: (typeof testers)[number]) => {
        if (!regToken) {
            return;
        }
        // const ttlMilliSeconds = 60 * 1000;

        if (mode === 'community_a') {
            // sends a message with a large image
            return () => {
                CommunityPushApi.CommunityTest({
                    regToken: testToken.length ? testToken : undefined,
                    message_community: {
                        title: 'Community Message Title',
                        body: 'Community Message Body ' + new Date(),
                        icon: 'https://www.rosterfy.com/hubfs/Rosterfy%20Favicon%20(1).png',
                        image: {
                            image: TEST_IMAGE_URL,
                            isCircular: true,
                        },
                        additionalAndroid: {
                            channelId: 'default',
                        },
                    },
                }).catch((err) => console.error(err));
            };
        } else if (mode === 'community_create_group') {
            // sends a message with a large image
            return () => {
                CommunityPushApi.CommunityTest({
                    regToken: testToken.length ? testToken : undefined,
                    message_community: {
                        title: 'Emails',
                        subtitle: '3 Unread emails',
                        icon: 'https://www.rosterfy.com/hubfs/Rosterfy%20Favicon%20(1).png',
                        additionalAndroid: {
                            channelId: 'default',
                            groupSummary: true,
                            groupId: 'testing_from_desktop',
                        },
                    },
                }).catch((err) => console.error(err));
            };
        } else if (mode === 'community_add_to_group') {
            // sends a message with a large image
            return () => {
                CommunityPushApi.CommunityTest({
                    regToken: testToken.length ? testToken : undefined,
                    message_community: {
                        title: 'New Email',
                        body: 'Tap to open your email',
                        icon: 'https://www.rosterfy.com/hubfs/Rosterfy%20Favicon%20(1).png',
                        image: {
                            image: TEST_IMAGE_URL,
                            isCircular: true,
                        },
                        additionalAndroid: {
                            channelId: 'default',
                            groupId: 'testing_from_desktop',
                        },
                    },
                }).catch((err) => console.error(err));
            };
        } else if (mode === 'community_load_test') {
            return () => {
                CommunityPushApi.CommunityTest({
                    load_test: true,
                    load_test_number: 50,
                }).catch((err) => console.error(err));
            };
        }
        return undefined;
    };

    return (
        <Stack spacing="1">
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Push - Last received message
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Text fontWeight="bold">{lastMessageTime}</Text>
                    <Text>{lastMessage}</Text>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Push - RegToken
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Text fontWeight={'bold'}>Push supported?</Text>
                    <Text>{isSupported ? 'true' : 'false'}</Text>
                    <Text fontWeight={'bold'}>RegToken</Text>
                    <Text>{regToken}</Text>
                </CardBody>
                <CardFooter>
                    <Button
                        type="button"
                        isDisabled={!isEnabled || !!regToken}
                        onClick={createRegToken}
                        isLoading={!regToken && pushLoading}
                    >
                        Request RegToken
                    </Button>
                    <Button
                        type="button"
                        isDisabled={!isEnabled || !regToken}
                        onClick={deleteRegToken}
                        isLoading={!!regToken && pushLoading}
                    >
                        Delete RegToken
                    </Button>
                </CardFooter>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Push - Send a test message
                    </Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={onSubmit}>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Test token</FormLabel>
                                <Input
                                    name="test_token"
                                    type="input"
                                    value={testToken}
                                    onChange={(e) => setTestToken(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Account Rrn Test</FormLabel>
                                <Input
                                    name="accountRrn"
                                    type="input"
                                    value={accountRrn}
                                    onChange={(e) => setAccountRrn(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Subtitle (Desktop | Android | IOS)</FormLabel>
                                <Input
                                    name="subtitle"
                                    type="input"
                                    value={subtitle}
                                    onChange={(e) => setSubtitle(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Title (Desktop | Android | IOS)</FormLabel>
                                <Input
                                    name="title"
                                    type="input"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Body (Desktop | Android | IOS)</FormLabel>
                                <Textarea name="body" value={body} onChange={(e) => setBody(e.target.value)} required />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Icon (Desktop)</FormLabel>
                                <Input name="icon" value={icon} onChange={(e) => setIcon(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Image URL (Android | IOS)</FormLabel>
                                <Input name="image_url" value={image} onChange={(e) => setImage(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <Checkbox
                                    isChecked={imageIsCircular}
                                    onChange={() => {
                                        setImageIsCircular(!imageIsCircular);
                                    }}
                                >
                                    Image IsCircular
                                </Checkbox>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Android Channel (Android)</FormLabel>
                                <Input
                                    name="android_channel"
                                    value={channel}
                                    onChange={(e) => setChannel(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <Checkbox
                                    isChecked={actionScheme}
                                    onChange={() => {
                                        setActionScheme(!actionScheme);
                                    }}
                                >
                                    Include test actions (Android)
                                </Checkbox>
                            </FormControl>
                            <FormControl>
                                <Checkbox
                                    isChecked={highPriority}
                                    onChange={() => {
                                        setHighPriority(!highPriority);
                                    }}
                                >
                                    High Priority (Android | IOS)
                                </Checkbox>
                            </FormControl>

                            <Text fontWeight={'bold'}>Response</Text>
                            <Text>{testResponse}</Text>

                            <Stack direction="row" spacing={1}>
                                <Button type="submit" isDisabled={!regToken} isLoading={testLoading}>
                                    Send
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Message testing
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Stack spacing={2}>
                        {testers.map((mode) => {
                            const send = sendTestMessage(mode);
                            return (
                                <Button
                                    key={mode}
                                    type="button"
                                    onClick={send}
                                    isDisabled={!regToken}
                                    isLoading={testLoading}
                                >
                                    {mode}
                                </Button>
                            );
                        })}
                    </Stack>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Notifications
                    </Heading>
                </CardHeader>
                <CardBody>
                    <Stack spacing={4} direction="row" align="center" mb="1rem">
                        <Button size="md" onClick={prevPage} isDisabled={!prevIndex}>
                            Prev
                        </Button>
                        <Button size="md" onClick={nextPage} isDisabled={!nextIndex}>
                            Next
                        </Button>
                    </Stack>

                    <List spacing={3}>
                        {notifications.map((notification) => {
                            const [, , notificationId] = notification.pk.split('#');
                            return (
                                <Card key={notification.pk}>
                                    <CardHeader>
                                        <Heading size="sm" fontWeight={700}>
                                            {notification.body.title} {notificationId}
                                        </Heading>
                                    </CardHeader>
                                    <CardBody>
                                        <Text>{notification.body.body}</Text>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            type="button"
                                            onClick={async () => {
                                                setDeleting(true);
                                                try {
                                                    await deleteNotification({ notificationId });
                                                    setNotifications(
                                                        notifications.filter((n) => n.pk !== notification.pk)
                                                    );
                                                } catch (error) {
                                                    console.error(error);
                                                }
                                                setDeleting(false);
                                            }}
                                            isLoading={deleting}
                                        >
                                            Delete
                                        </Button>
                                    </CardFooter>
                                </Card>
                            );
                        })}
                    </List>

                    <Stack spacing={4} direction="row" align="center" mt="1rem">
                        <Button size="md" onClick={prevPage} isDisabled={!prevIndex}>
                            Prev
                        </Button>
                        <Button size="md" onClick={nextPage} isDisabled={!nextIndex}>
                            Next
                        </Button>
                    </Stack>
                </CardBody>
            </Card>
        </Stack>
    );
};
