import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommunityAuth } from '../components/auth';
import { auth } from '../components/firebase';

export const MeTest: FC = () => {
    const { authenticated, signOut } = useCommunityAuth();
    const navigate = useNavigate();

    return authenticated ? (
        <Box maxW="400px" mx="auto">
            <Stack spacing={2}>
                <Card>
                    <CardHeader>
                        <Heading size="lg" fontWeight={700}>
                            Welcome to Community, {authenticated.object.first_name}
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <Text>{JSON.stringify(authenticated.object)}</Text>
                    </CardBody>
                    <CardFooter>
                        <Stack spacing="1" direction="row" wrap="wrap">
                            <Button
                                type="button"
                                onClick={() => {
                                    signOut(auth).catch((err) => console.error(err));
                                }}
                            >
                                Signout
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    navigate('/push_test');
                                }}
                            >
                                Push Test
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    navigate('/translation_test');
                                }}
                            >
                                Translation Test
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    navigate('/search_test');
                                }}
                            >
                                Search (OPP) Test
                            </Button>
                            <Button
                                type="button"
                                onClick={() => {
                                    navigate('/account_linking_test');
                                }}
                            >
                                Account Linking
                            </Button>
                        </Stack>
                    </CardFooter>
                </Card>
            </Stack>
        </Box>
    ) : (
        <></>
    );
};
