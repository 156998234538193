import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    useToast,
} from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { CustomFirebaseUiActions } from './reducer';
import { OtlcService } from '../../community-service/otlc-service';
import { useAppDispatch } from '../../hooks';
import { useCommunityAuth } from '../auth';
import { auth } from '../firebase';

const otlc = OtlcService();

const isIOSStoreEmail = (email: string) => {
    return email === 'technology+appstore-review@rosterfy.com';
};

export const LoginEmailPanel: FC = () => {
    const dispatch = useAppDispatch();
    const { signInWithEmailAndPassword } = useCommunityAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const passwordFlow = useMemo(() => {
        return isIOSStoreEmail(email);
    }, [email]);

    const onEmailPasswordSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (email) {
            setIsLoading(true);
            try {
                await signInWithEmailAndPassword(auth, email, password);
                dispatch(CustomFirebaseUiActions.setView(undefined));
            } catch (error: any) {
                console.error(error);
                toast({
                    title: 'Invalid credentials',
                    status: 'error',
                    duration: 9000,
                    position: 'top-right',
                    isClosable: true,
                });
            }
            setIsLoading(false);
        }
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (email) {
            if (passwordFlow) {
                onEmailPasswordSubmit(e);
                return;
            }
            setIsLoading(true);
            try {
                await otlc.requestEmail(email);
                dispatch(CustomFirebaseUiActions.setLastSentEmail(email));
                dispatch(CustomFirebaseUiActions.setView('login_email_sent'));
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        }
    };

    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <Box>
            <Box as="form" onSubmit={onSubmit}>
                <Card>
                    <CardHeader>
                        <Heading size="md">Sign in with email</Heading>
                    </CardHeader>
                    <CardBody>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    autoFocus={true}
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </FormControl>
                            {passwordFlow && (
                                <FormControl>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={password}
                                            required={true}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button h="1.75rem" size="sm" onClick={togglePassword}>
                                                {showPassword ? 'Hide' : 'Show'}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                            )}
                        </Stack>
                    </CardBody>
                    <CardFooter>
                        <Stack direction="row" spacing={2}>
                            <Button
                                type="button"
                                onClick={() => {
                                    dispatch(CustomFirebaseUiActions.setView(undefined));
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                                {passwordFlow ? 'Login' : 'Next'}
                            </Button>
                        </Stack>
                    </CardFooter>
                </Card>
            </Box>
        </Box>
    );
};
