import { Button } from '@chakra-ui/react';
import { FacebookAuthProvider } from 'firebase/auth';
import { FC } from 'react';
import { FaFacebook } from 'react-icons/fa';
import { ProviderButtonMixin } from '../../styles/mixins-button';
import { useCommunityAuth } from '../auth/useCommunityAuth';
import { auth } from '../firebase';

const facebookProvider = new FacebookAuthProvider();
facebookProvider.addScope('email');
facebookProvider.addScope('public_profile');

export const FacebookLoginButton: FC = () => {
    const { autoDetectSigninWith } = useCommunityAuth();
    const onLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        autoDetectSigninWith('facebook')(auth, facebookProvider).catch((err) => console.error(err));
    };

    return (
        <Button
            type="button"
            onClick={onLogin}
            leftIcon={<FaFacebook color="#4267B2" size="1.125rem" />}
            {...ProviderButtonMixin}
        >
            Continue with Facebook
        </Button>
    );
};
