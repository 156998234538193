import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { CustomFirebaseUiActions } from './reducer';
import { useAppDispatch, useAppSelector } from '../../hooks';

export const LoginEmailSentPanel: FC = () => {
    const dispatch = useAppDispatch();
    const last_sent_email = useAppSelector((state) => state.custom_firebase_ui.last_sent_email);
    return (
        <Box>
            <Card>
                <CardHeader>
                    <Heading size="md">Sign-in email sent</Heading>
                </CardHeader>

                <CardBody>
                    <Text>
                        A sign-in email with additional instructions was sent to <strong>{last_sent_email}</strong>.
                        Check your email to complete sign-in.
                    </Text>
                </CardBody>

                <CardFooter>
                    <Button
                        onClick={() => {
                            dispatch(CustomFirebaseUiActions.setView(undefined));
                        }}
                    >
                        Back
                    </Button>
                </CardFooter>
            </Card>
        </Box>
    );
};
