import { AppEnv } from '../../config';

const {
    FIREBASE_APIKEY,
    FIREBASE_AUTHDOMAIN,
    FIREBASE_PROJECTID,
    FIREBASE_STORAGEBUCKET,
    FIREBASE_MESSAGINGSENDERID,
    FIREBASE_APPID,
    FIREBASE_PROVIDER_GOOGLE_CLIENTID,
} = AppEnv;

export const getFirebaseConfig = () => {
    return {
        apiKey: FIREBASE_APIKEY,
        authDomain: FIREBASE_AUTHDOMAIN,
        projectId: FIREBASE_PROJECTID,
        storageBucket: FIREBASE_STORAGEBUCKET,
        messagingSenderId: FIREBASE_MESSAGINGSENDERID,
        appId: FIREBASE_APPID,
    };
};

export const SETTINGS_PROVIDER_GOOGLE = {
    clientId: FIREBASE_PROVIDER_GOOGLE_CLIENTID,
};
