import { ButtonProps } from '@chakra-ui/react';
import { rosterfyBgGradient } from '../theme-brand';

export const ProviderButtonMixin: ButtonProps = {
    sx: {
        position: 'relative',
        height: '3rem',
        '.chakra-button__icon': { position: 'absolute', left: '1rem' },
    },
    variant: 'outline',
};

export const ProviderEmailButtonMixin: ButtonProps = {
    sx: {
        ...ProviderButtonMixin.sx,
    },
    color: 'white',
    variant: 'solid',
    bgGradient: rosterfyBgGradient,
    _hover: {
        bgGradient: rosterfyBgGradient,
        opacity: 0.9,
    },
};
