import { Button } from '@chakra-ui/react';
import { GoogleAuthProvider } from 'firebase/auth';
import { FC } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { ProviderButtonMixin } from '../../styles/mixins-button';
import { useCommunityAuth } from '../auth/useCommunityAuth';
import { auth } from '../firebase';

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
    prompt: 'select_account',
});

export const GoogleLoginButton: FC = () => {
    const { autoDetectSigninWith } = useCommunityAuth();
    const onLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        autoDetectSigninWith('google')(auth, googleProvider).catch((err) => console.error(err));
    };

    return (
        <Button type="button" onClick={onLogin} leftIcon={<FcGoogle size="1.125rem" />} {...ProviderButtonMixin}>
            Continue with Google
        </Button>
    );
};
