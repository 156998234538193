import axios from 'axios';
import { CommunityUser } from './types';
import { CommunityEndpoint } from '..';

const GetUserMe = () => {
    return axios.get<CommunityUser.UserMeResponse>(`${CommunityEndpoint}/user/me`);
};

const PostUserRegister = (form: CommunityUser.UserRegisterForm) => {
    return axios.post<CommunityUser.UserRegisterResponse>(`${CommunityEndpoint}/user/register`, form);
};

const GetUserRegister = () => {
    return axios.get<CommunityUser.UserRegisterResponse>(`${CommunityEndpoint}/user/register`);
};

export const CommunityUserApi = {
    GetUserMe,
    PostUserRegister,
    GetUserRegister,
};
