import { getToken, deleteToken as firebaseDeleteToken } from 'firebase/messaging';
import { useState } from 'react';
import { CommunityPushApi } from '../../community-api/push/api';
import { CommunityPush } from '../../community-api/push/types';
import { AppEnv } from '../../config';
import { messaging } from '../firebase';

const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        return permission;
    } catch (error) {
        console.error(error);
    }
    return;
};

const getRegToken = async () => {
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: AppEnv.FIREBASE_MESSAGING_KEY,
        });
        return currentToken;
    } catch (error) {
        console.error(error);
    }
    return null;
};

export const usePush = () => {
    const [isSupported, setIsSupported] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [regToken, setRegToken] = useState<string | null>();
    const [lastMessage, setLastMessage] = useState('');
    const [lastMessageTime, setLastMessageTime] = useState('');

    const createRegToken = async () => {
        setIsLoading(true);
        const permission = await requestPermission();
        let newToken: string | null = null;
        if (permission === 'granted') {
            newToken = await getRegToken();
        }
        setRegToken(newToken);
        setIsLoading(false);
        return newToken;
    };

    const deleteRegToken = async () => {
        setIsLoading(true);
        try {
            const token = await getRegToken();
            const res = await firebaseDeleteToken(messaging);
            if (res) {
                setRegToken(null);
                if (token) {
                    try {
                        await CommunityPushApi.DeregisterToken({ token });
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    };

    const getNotificationsPaginated = async (params: CommunityPush.GetNotificationsPaginatedParams) => {
        try {
            const res = await CommunityPushApi.GetNotificationsPaginated(params);
            return res;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const deleteNotification = async (params: CommunityPush.DeleteNotificationParams) => {
        try {
            const res = await CommunityPushApi.DeleteNotification(params);
            return res;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    return {
        requestPermission,
        regToken,
        setRegToken,
        getRegToken,
        createRegToken,
        deleteRegToken,
        isSupported,
        isEnabled: isEnabled && isSupported,
        isLoading,
        setIsLoading,
        lastMessage,
        lastMessageTime,
        setIsSupported,
        setIsEnabled,
        setLastMessage,
        setLastMessageTime,
        getNotificationsPaginated,
        deleteNotification,
    };
};

export type PushHandler = ReturnType<typeof usePush>;
