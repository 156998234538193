import { Button, Card, CardBody, CardFooter, CardHeader, Heading, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useRosterfyTranslation } from './useRosterfyTranslation';

const rosterfyTranslationList = [
    'login:core',
    'login:welcome',
    'login:timeout.more_time',
    'account:go_to_subaccount',
    'account:billing.stats.total_system_admins',
    'passwords:reset_confirm',
    'passwords:reset_password',
    'portal:social.login_facebook',
];

export const TranslationTest: FC = () => {
    const { t, changeLanguage } = useRosterfyTranslation();

    const changeLanguageHandler = (language: string) => {
        return () => {
            changeLanguage(language).catch((err) => console.error(err));
        };
    };

    return (
        <Stack spacing="1">
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Translations
                    </Heading>
                </CardHeader>
                <CardBody>
                    {/* <Text fontWeight="bold">Soldier</Text>
                    <Text>{t('common.universal.soldier')}</Text>
                    <Text fontWeight="bold">Hello</Text>
                    <Text>{t('common.hello')}</Text>
                    <Text fontWeight="bold">World</Text>
                    <Text>{t('common.world')}</Text> */}
                    {rosterfyTranslationList.map((key) => {
                        return (
                            <div key={key}>
                                <Text fontWeight="bold">Rosterfy Translation: {key}</Text>
                                <Text>{t(key)}</Text>
                            </div>
                        );
                    })}
                </CardBody>
                <CardFooter>
                    <Stack spacing="1" direction="row">
                        <Button type="button" onClick={changeLanguageHandler('en')}>
                            En - English
                        </Button>
                        <Button type="button" onClick={changeLanguageHandler('de')}>
                            De - German
                        </Button>
                        <Button type="button" onClick={changeLanguageHandler('ar')}>
                            Ar - Arabic
                        </Button>
                    </Stack>
                </CardFooter>
            </Card>
        </Stack>
    );
};
