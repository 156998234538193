import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type CustomFirebaseUiState = {
    view: 'login_email' | 'login_email_sent' | 'login_email_password' | undefined;
    last_sent_email?: string;
};

const initialState: CustomFirebaseUiState = {
    view: undefined,
    last_sent_email: undefined,
};

const CustomFirebaseUiSlice = createSlice({
    name: 'CustomFirebaseUiState',
    initialState,
    reducers: {
        setView: (state, action: PayloadAction<CustomFirebaseUiState['view']>) => {
            state.view = action.payload;
        },
        setLastSentEmail: (state, action: PayloadAction<CustomFirebaseUiState['last_sent_email']>) => {
            state.last_sent_email = action.payload;
        },
    },
});

export const CustomFirebaseUiReducer = CustomFirebaseUiSlice.reducer;
export const CustomFirebaseUiActions = CustomFirebaseUiSlice.actions;
