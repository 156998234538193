import { useLocation, useNavigate } from 'react-router-dom';
import { AccessTokenParams } from './useAccessTokenHandler';

export const useAccessTokenUrlDirector = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const prepareUrl = (params: AccessTokenParams) => {
        const { redirectState } = params;

        // update status
        const fragment = new URLSearchParams('?' + location.hash.slice(1, location.hash.length));
        fragment.set('state', JSON.stringify({ ...redirectState, status: 'ready_for_signin' }));
        const newHash = '#' + fragment.toString();

        if (redirectState.pathOverride || redirectState.searchOverride) {
            navigate({
                pathname: redirectState.pathOverride,
                search: redirectState.searchOverride,
                hash: newHash,
            });
        } else {
            navigate({
                pathname: location.pathname,
                search: location.search,
                hash: newHash,
            });
        }
    };

    const cleanUrl = () => {
        navigate({
            pathname: location.pathname,
            search: location.search,
            hash: undefined,
        });
    };

    return { prepareUrl, cleanUrl };
};

export type AccessTokenUrlDirector = ReturnType<typeof useAccessTokenUrlDirector>;
