import axios from 'axios';
import { CommunitySearch } from './types';
import { CommunityEndpoint } from '..';

type SearchOpportunityOptions = {
    locale?: string;
};

const SearchOpportunity = (search: string, opts: SearchOpportunityOptions = {}) => {
    const { locale } = opts;
    console.log(search);
    return axios.get<CommunitySearch.SearchOpportunityRespose>(`${CommunityEndpoint}/opportunity/search`, {
        params: {
            search,
            locale,
        },
    });
};

type DebugOppKey = 'search' | 'locale' | 'mapping_test' | 'geo_filtering_test' | 'delete_index' | 'dynamic_template';

const DebugOpp = (params: Partial<Record<DebugOppKey, any>> = {}) => {
    return axios.get<any>(`${CommunityEndpoint}/debug/es`, {
        params,
    });
};

export const CommunitySearchApi = {
    SearchOpportunity,
    DebugOpp,
};
