import { Box } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { AuthAlert } from './AuthAlert';
import { LoginEmailPanel } from './LoginEmailPanel';
import { LoginEmailPasswordPanel } from './LoginEmailPasswordPanel';
import { LoginEmailSentPanel } from './LoginEmailSentPanel';
import { LoginPanel } from './LoginPanel';
import { useAppSelector } from '../../hooks';
import { useCommunityAuth } from '../auth';

export const CustomFirebaseUi: FC = () => {
    const { isLoading } = useCommunityAuth();
    const view = useAppSelector((state) => state.custom_firebase_ui.view);

    const viewComponent = useMemo(() => {
        switch (view) {
            case 'login_email':
                return <LoginEmailPanel />;
            case 'login_email_sent':
                return <LoginEmailSentPanel />;
            case 'login_email_password':
                return <LoginEmailPasswordPanel />;
            default:
                return <LoginPanel />;
        }
    }, [view]);

    return (
        <Box display={isLoading ? 'none' : 'block'}>
            {viewComponent}
            {<AuthAlert />}
        </Box>
    );
};
