import { ChakraBaseProvider } from '@chakra-ui/react';
import { FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Community } from './Community';
import { store } from './store';
import { theme } from './theme';
import '@fontsource/raleway';

export const App: FC = () => {
    return (
        <Router>
            <ReduxProvider store={store}>
                <ChakraBaseProvider theme={theme}>
                    <Community />
                </ChakraBaseProvider>
            </ReduxProvider>
        </Router>
    );
};
