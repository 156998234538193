import { LanguageDetectorAsyncModule } from 'i18next';
// import { addTranslationNamespace } from './utils';

export const translationDefaultLang = 'en';

const rosterfyResources = {
    en: {
        common: {
            hello: 'Hello',
            world: 'World',
            universal: {
                soldier: 'Soldier',
                maths: 'Maths',
            },
        },
        test: {
            time: 'Time',
        },
    },
    de: {
        common: {
            hello: 'Hallo',
            world: 'Welt',
            universal: {
                soldier: 'Soldat',
                maths: 'Mathe',
            },
        },
        test: {
            time: 'Zeit',
        },
    },
};

// export const translationResources = addTranslationNamespace(rosterfyResources);
export const translationResources = rosterfyResources;

export const translationNamespaces = ['common', 'test'] as const;

export const languageDetector: LanguageDetectorAsyncModule = {
    type: 'languageDetector',
    async: true,
    detect: (_) => {
        // const [systemLang] = getLocales();
        // callback(systemLang.languageCode);
    },
    init: () => {},
    cacheUserLanguage: () => {},
};
