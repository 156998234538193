import { isSupported as firebaseIsSupported, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { handleFirebaseMessage } from './handleFirebaseMessage';
import { PushHandler } from './usePush';
import { CommunityPushApi } from '../../community-api/push/api';
import { messaging } from '../firebase';

export const registerPush = (push: PushHandler) => {
    const {
        getRegToken,
        setIsEnabled,
        setRegToken,
        setIsSupported,
        regToken,
        setLastMessage,
        setLastMessageTime,
        requestPermission,
    } = push;

    useEffect(() => {
        requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    setIsEnabled(true);
                }
            })
            .catch((err) => console.error(err));
        getRegToken()
            .then((token) => {
                setRegToken(token);
                if (token) {
                    CommunityPushApi.RegisterToken({ token }).catch((err) => console.error(err));
                }
            })
            .catch((err) => console.error(err));
        firebaseIsSupported()
            .then((supported) => {
                setIsSupported(supported);
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (!regToken) return;
        const onMessageDown = onMessage(messaging, (remoteMessage) => {
            // debugging
            setLastMessage(JSON.stringify(remoteMessage));
            setLastMessageTime(new Date() + '');
            handleFirebaseMessage(remoteMessage);
        });

        return () => {
            onMessageDown();
        };
    }, [!!regToken]);
};
