import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.Fragment>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
    </React.Fragment>
);
