import { Box } from '@chakra-ui/react';
import { FC } from 'react';
import { registerRedirectResultHandler, useCommunityAuth } from '../components/auth';
import { AuthAlert } from '../components/custom-firebase-ui/AuthAlert';
import { CustomFirebaseUi } from '../components/custom-firebase-ui/CustomFirebaseUi';
import { Spinner } from '../styles/Spinner';

export const Auth: FC = () => {
    const authHandler = useCommunityAuth();
    const { isLoading } = authHandler;
    registerRedirectResultHandler(authHandler);

    return (
        <Box maxW="400px" mx="auto">
            {isLoading && (
                <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
                    <Spinner />
                </Box>
            )}
            <CustomFirebaseUi />
            <AuthAlert />
        </Box>
    );
};
