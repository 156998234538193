import { MessagePayload } from 'firebase/messaging';

const getCommunityNotificationFromPayload = (payload: MessagePayload) => {
    const { data, notification } = payload;
    try {
        if (data && data.web) {
            const webNotification = JSON.parse(data.web) as Notification;
            return webNotification;
        } else if (notification) {
            return notification;
        }
    } catch (error) {}
    return undefined;
};

export const handleFirebaseMessage = (payload: MessagePayload) => {
    try {
        const notification = getCommunityNotificationFromPayload(payload);
        if (notification) {
            const { title } = notification;
            new Notification(title ? title : 'Untitled notification', notification);
            // notification.onclick = () => {
            //     window.open(url, '_blank');
            // };
        }
    } catch (error) {
        console.error(error);
    }
};
