import { Button } from '@chakra-ui/react';
import { OAuthProvider } from 'firebase/auth';
import { FC } from 'react';
import { FaApple } from 'react-icons/fa';
import { ProviderButtonMixin } from '../../styles/mixins-button';
import { useCommunityAuth } from '../auth';
import { auth } from '../firebase';

const appleProvider = new OAuthProvider('apple.com');

export const AppleLoginButton: FC = () => {
    const { autoDetectSigninWith } = useCommunityAuth();
    const onLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        autoDetectSigninWith('apple')(auth, appleProvider).catch((err) => console.error(err));
    };
    return (
        <Button type="button" onClick={onLogin} leftIcon={<FaApple size="1.125rem" />} {...ProviderButtonMixin}>
            Continue with Apple
        </Button>
    );
};
