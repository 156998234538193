import axios from 'axios';
import { CommunityPush } from './types';
import { CommunityEndpoint } from '..';

/**
 * Community
 */

const RegisterToken = async (params: CommunityPush.RegisterParams) => {
    return axios.post<CommunityPush.RegisterResponse>(`${CommunityEndpoint}/push/token`, params);
};

const DeregisterToken = async (params: CommunityPush.DeregisterParams) => {
    return axios.delete<CommunityPush.DeregisterResponse>(`${CommunityEndpoint}/push/token`, {
        data: params,
    });
};

const CommunityTest = async (params: CommunityPush.CommunityTestParams) => {
    return axios.post<CommunityPush.CommunityTestResponse>(`${CommunityEndpoint}/push/test`, params);
};

const GetNotificationsPaginated = async (params: CommunityPush.GetNotificationsPaginatedParams) => {
    return axios.get<CommunityPush.GetNotificationsPaginatedResponse>(`${CommunityEndpoint}/notifications`, {
        params,
    });
};

const DeleteNotification = async (params: CommunityPush.DeleteNotificationParams) => {
    return axios.delete<CommunityPush.DeleteNotificationResponse>(`${CommunityEndpoint}/notifications`, {
        data: params,
    });
};

export const CommunityPushApi = {
    RegisterToken,
    DeregisterToken,
    CommunityTest,
    GetNotificationsPaginated,
    DeleteNotification,
};
