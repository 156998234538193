import { Box, Button, FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommunityUserApi } from '../community-api/users/api';
import { useCommunityAuth } from '../components/auth';
import { auth } from '../components/firebase';

export const Registration: FC = () => {
    const { loadAuthenticated } = useCommunityAuth();
    const navigate = useNavigate();
    const user = auth.currentUser;
    const [email, setEmail] = React.useState(user && user.email ? user.email : '');
    const [first_name, setFirstName] = React.useState('');
    const [last_name, setLastName] = React.useState('');
    const [preferred_name, setPreferredName] = React.useState('');
    const [yob, setYob] = React.useState('2020');
    const [nationality, setNationality] = React.useState('Australia');
    const [location, setLocation] = React.useState('Victoria');
    const [phone, setPhone] = React.useState('+61420123456');
    // const [is_subscribed, setIsSubscribed] = React.useState(false);

    const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        try {
            const res = await CommunityUserApi.PostUserRegister({
                email,
                first_name,
                last_name,
                preferred_name,
                yob: Number(yob) ? Number(yob) : 2023,
                nationality,
                location,
                phone,
                is_subscribed: true,
                eula_version: 0,
            });
            console.log(res);
            await loadAuthenticated();
            navigate('/');
        } catch (error) {
            console.error(error);
        }
    };

    return user ? (
        <Box maxW="400px" mx="auto">
            <Box as="form">
                <Stack spacing={4}>
                    <Heading size="md">Account registration</Heading>
                    <FormControl>
                        <FormLabel>Email address</FormLabel>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email address"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>First name</FormLabel>
                        <Input
                            name="first_name"
                            type="input"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Last name</FormLabel>
                        <Input
                            name="last_name"
                            type="input"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Preferred name</FormLabel>
                        <Input
                            name="preferred_name"
                            type="input"
                            value={preferred_name}
                            onChange={(e) => setPreferredName(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>YOB</FormLabel>
                        <Input name="yob" type="input" value={yob} onChange={(e) => setYob(e.target.value)} required />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Nationality</FormLabel>
                        <Input
                            name="nationality"
                            type="input"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Location</FormLabel>
                        <Input
                            name="location"
                            type="input"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Phone</FormLabel>
                        <Input
                            name="phone"
                            type="input"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </FormControl>

                    <Stack direction="row" spacing={1}>
                        <Button type="button" onClick={onSubmit}>
                            Register
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    ) : (
        <></>
    );
};
