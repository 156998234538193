import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { AppleLoginButton } from './AppleLoginButton';
import { EmailLoginButton } from './EmailLoginButton';
import { FacebookLoginButton } from './FacebookLoginButton';
import { GoogleLoginButton } from './GoogleLoginButton';
import { registerOneTap } from '../auth/registerOneTap';
import { useCommunityAuth } from '../auth/useCommunityAuth';

export const LoginPanel: FC = () => {
    const auth = useCommunityAuth();
    registerOneTap(auth);
    return (
        <Box>
            <Box mb="2rem" textAlign={'center'}>
                <Heading size="lg" fontWeight={700} my="0.5rem">
                    Welcome to Rosterfy!
                </Heading>
                <Text color="grey">Choose a method to continue</Text>
            </Box>
            <VStack spacing={4} align="stretch">
                <GoogleLoginButton />
                <AppleLoginButton />
                <FacebookLoginButton />
                <EmailLoginButton />
            </VStack>
        </Box>
    );
};
