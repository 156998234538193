import {
    User,
    signOut as firebaseSignout,
    Auth,
    getRedirectResult as firebaseGetRedirectResult,
    signInWithRedirect as firebaseSignInWithRedirect,
    signInWithCustomToken as firebaseSignInWithCustomToken,
    signInWithCredential as firebaseSignInWithCredential,
    signInWithPopup as firebaseSigninWithPopup,
    signInWithEmailAndPassword as firebaseSigninWithEmailAndPassword,
    UserCredential,
    IdTokenResult,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
} from 'firebase/auth';
import platform from 'platform';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AUTH_CALLBACK_PARAMETER } from './constants';
import { googleCustomLogin, facebookCustomLogin, appleCustomLogin } from './customLogin';
import { AuthActions } from './reducer';
import { AccessTokenParams } from './useAccessTokenHandler';
import { useAccessTokenUrlDirector } from './useAccessTokenUrlDirector';
import { CommunityAuthApi } from '../../community-api/auth/api';
import { CommunityUserApi } from '../../community-api/users/api';
import { CommunityUser } from '../../community-api/users/types';
import { useAppDispatch, useAppSelector } from '../../hooks';

export type LoginAction =
    | {
          method: 'getRedirectResult';
          args: Parameters<typeof firebaseGetRedirectResult>;
      }
    | {
          method: 'signInWithRedirect';
          args: Parameters<typeof firebaseSignInWithRedirect>;
      }
    | {
          method: 'signInWithCustomToken';
          args: Parameters<typeof firebaseSignInWithCustomToken>;
      }
    | {
          method: 'signInWithCredential';
          args: Parameters<typeof firebaseSignInWithCredential>;
      }
    | {
          method: 'signinWithPopup';
          args: Parameters<typeof firebaseSigninWithPopup>;
      }
    | {
          method: 'signInWithEmailAndPassword';
          args: Parameters<typeof firebaseSigninWithEmailAndPassword>;
      }
    | {
          method: 'signinWithGoogleCustomRedirect';
          args: Parameters<ReturnType<typeof googleCustomLogin>['start']>;
      }
    | {
          method: 'signinWithFacebookCustomRedirect';
          args: Parameters<ReturnType<typeof facebookCustomLogin>['start']>;
      }
    | {
          method: 'signinWithAppleCustomRedirect';
          args: Parameters<ReturnType<typeof appleCustomLogin>['start']>;
      }
    | {
          method: 'signinWithAccessTokenResult';
          args: [Auth, AccessTokenParams];
      }
    | {
          method: 'getAccessTokenResult';
          args: [Auth, AccessTokenParams | undefined];
      };

export type AuthConfig = {
    beforeInitialise?: (accessor: Accessor) => Promise<void>;
    initialise?: (accessor: Accessor) => Promise<void>;
    beforeLogin?: (accessor: Accessor, action: LoginAction) => Promise<void>;
    afterCredentials?: (accessor: Accessor, credential: UserCredential | null, action: LoginAction) => Promise<void>;
    login?: (accessor: Accessor, credential: UserCredential, action: LoginAction) => Promise<void>;
    loginWithCallback?: (
        accessor: Accessor,
        callbackUrl: string,
        credential: UserCredential,
        action: LoginAction
    ) => Promise<void>;
    loginNoResult?: (accessor: Accessor, action: LoginAction) => Promise<void>;
    loginError?: (accessor: Accessor, error: any, action: LoginAction) => Promise<void>;
    signout?: (accessor: Accessor) => Promise<void>;
    beforeAuthenticated?: (accessor: Accessor) => Promise<void>;
    authenticated?: (accessor: Accessor, data: CommunityUser.UserMeResponse) => Promise<void>;
    beforeRefreshIdToken?: (accessor: Accessor) => Promise<void>;
    afterRefreshIdToken?: (accessor: Accessor, idTokenResult: IdTokenResult) => Promise<void>;
    idTokenChanged?: (accessor: Accessor, user: User | null) => Promise<void>;
};

export const useAuth = (configuration: AuthConfig) => {
    const {
        beforeInitialise: _beforeInitialise,
        initialise: onInitialise,
        beforeLogin,
        afterCredentials,
        login: onLogin,
        loginWithCallback: onLoginWithCallback,
        loginNoResult: onLoginNoResult,
        loginError: onLoginError,
        signout: onSignout,
        beforeAuthenticated,
        authenticated: onAuthenticated,
        idTokenChanged: onIdTokenChanged,
        beforeRefreshIdToken,
        afterRefreshIdToken,
    } = configuration;
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const ready = useAppSelector((state) => state.auth.ready);
    const isAuthLoading = useAppSelector((state) => state.auth.isLoading);
    const isRedirectResultLoading = useAppSelector((state) => state.auth.redirectResultLoading);
    const accessTokenLoading = useAppSelector((state) => state.auth.accessTokenLoading);
    const loginCallbackLoading = useAppSelector((state) => state.auth.loginCallbackLoading);
    const isLoading = isAuthLoading || isRedirectResultLoading || accessTokenLoading || loginCallbackLoading;
    const authenticated = useAppSelector((state) => state.auth.authenticated);
    const authenticatedChecked = typeof authenticated !== 'undefined';

    const { prepareUrl, cleanUrl } = useAccessTokenUrlDirector();
    const _signinWithGoogleCustomRedirect = googleCustomLogin({});
    const _signinWithFacebookCustomRedirect = facebookCustomLogin({});
    const _signinWithAppleCustomRedirect = appleCustomLogin({});

    const dispatch = useAppDispatch();

    const beforeInitialise = async () => {
        try {
            _beforeInitialise && (await _beforeInitialise(accessor));
        } catch (error) {
            console.error(error);
        }
    };

    const getAuthenticated = async () => {
        const res = await CommunityUserApi.GetUserMe();
        return res.data;
    };

    const loadAuthenticated = async () => {
        try {
            beforeAuthenticated && (await beforeAuthenticated(accessor));
            const meData = await getAuthenticated();
            onAuthenticated && (await onAuthenticated(accessor, meData));
        } catch (error: any) {
            throw error;
        }
    };

    const getAuthCallback = () => {
        return params.get(AUTH_CALLBACK_PARAMETER);
    };

    const extendLoginMethod = <Args extends unknown[]>(
        method: LoginAction['method'],
        getCredential: (...args: Args) => Promise<UserCredential | null>
    ): ((...args: Args) => Promise<UserCredential | null>) => {
        return async (...args) => {
            const action = { method, args } as unknown as LoginAction;
            try {
                beforeLogin && (await beforeLogin(accessor, action));
                const credential = await getCredential(...args);
                afterCredentials && (await afterCredentials(accessor, credential, action));

                if (credential) {
                    const authCallback = getAuthCallback();
                    if (authCallback) {
                        // Redirect process
                        onLoginWithCallback && (await onLoginWithCallback(accessor, authCallback, credential, action));
                    } else {
                        // Desktop process
                        onLogin && (await onLogin(accessor, credential, action));
                    }
                    return credential;
                } else {
                    onLoginNoResult && (await onLoginNoResult(accessor, action));
                    return null;
                }
            } catch (error) {
                onLoginError && (await onLoginError(accessor, error, action));
                throw error;
            }
        };
    };

    const refreshIdToken = async (firebaseAuth: Auth) => {
        const user = firebaseAuth.currentUser;
        if (user) {
            try {
                beforeRefreshIdToken && (await beforeRefreshIdToken(accessor));
                const result = await user.getIdTokenResult(true);
                afterRefreshIdToken && (await afterRefreshIdToken(accessor, result));
                return result.token;
            } catch (error) {
                console.error(error);
            }
        }
        return null;
    };

    /**
     * Firebase wrappers
     */

    const signOut = async (firebaseAuth: Auth) => {
        try {
            await firebaseSignout(firebaseAuth);
            onSignout && (await onSignout(accessor));
            return true;
        } catch (error) {
            console.error(error);
        }
        return false;
    };

    const getRedirectResult = extendLoginMethod('getRedirectResult', firebaseGetRedirectResult);

    const signInWithRedirect = extendLoginMethod('signInWithRedirect', firebaseSignInWithRedirect);

    const signInWithCustomToken = extendLoginMethod('signInWithCustomToken', firebaseSignInWithCustomToken);

    const signInWithCredential = extendLoginMethod('signInWithCredential', firebaseSignInWithCredential);

    const signinWithPopup = extendLoginMethod('signinWithPopup', firebaseSigninWithPopup);

    const signInWithEmailAndPassword = extendLoginMethod(
        'signInWithEmailAndPassword',
        firebaseSigninWithEmailAndPassword
    );

    const autoDetectSigninWith = (provider: 'google' | 'facebook' | 'apple') => {
        if (provider === 'apple') {
            return signinWithAppleCustomRedirect;
        }
        const storageDisabled = typeof Storage === 'undefined';
        if (platform.name === 'Chrome' && !storageDisabled) {
            return signInWithRedirect;
        } else {
            if (provider === 'google') {
                return signinWithGoogleCustomRedirect;
            } else if (provider === 'facebook') {
                return signinWithFacebookCustomRedirect;
            }
        }

        return signInWithRedirect;
    };

    /**
     * Custom signin flows
     */

    const signinWithGoogleCustomRedirect = extendLoginMethod(
        'signinWithGoogleCustomRedirect',
        _signinWithGoogleCustomRedirect.start
    );
    const signinWithFacebookCustomRedirect = extendLoginMethod(
        'signinWithFacebookCustomRedirect',
        _signinWithFacebookCustomRedirect.start
    );

    const signinWithAppleCustomRedirect = extendLoginMethod(
        'signinWithAppleCustomRedirect',
        _signinWithAppleCustomRedirect.start
    );

    const _signinWithAccessTokenResult = async (
        auth: Auth,
        accessTokenParams: AccessTokenParams
    ): Promise<UserCredential | null> => {
        const { results } = accessTokenParams;
        navigate({
            pathname: location.pathname,
            search: location.search,
            hash: undefined,
        });
        if (!results) {
            return null;
        }
        if (results.type === 'google' && results.accessToken) {
            const credential = GoogleAuthProvider.credential(null, results.accessToken);
            return firebaseSignInWithCredential(auth, credential);
        } else if (results.type === 'facebook' && results.accessToken) {
            const credential = FacebookAuthProvider.credential(results.accessToken);
            return firebaseSignInWithCredential(auth, credential);
        } else if (results.type === 'apple' && results.idToken) {
            const provider = new OAuthProvider('apple.com');
            const credential = provider.credential({
                idToken: results.idToken,
            });
            const userCredential = await firebaseSignInWithCredential(auth, credential);
            if (results.user) {
                try {
                    const idToken = await userCredential.user.getIdToken();
                    await CommunityAuthApi.SaveAppleLoginDetails(results.user, idToken);
                } catch (error) {
                    throw error;
                }
            }
            return userCredential;
        }
        return null;
    };

    const signinWithAccessTokenResult = extendLoginMethod('signinWithAccessTokenResult', _signinWithAccessTokenResult);

    /**
     * Access Token
     */

    const _getAccessTokenResult = async (
        auth: Auth,
        accessTokenParams: AccessTokenParams | undefined
    ): Promise<UserCredential | null> => {
        if (accessTokenParams) {
            if (accessTokenParams.redirectState.status === 'request') {
                prepareUrl(accessTokenParams);
            } else if (accessTokenParams.redirectState.status === 'ready_for_signin') {
                return _signinWithAccessTokenResult(auth, accessTokenParams).catch((err) => {
                    cleanUrl();
                    console.error(err);
                    throw err;
                });
            }
        }
        return null;
    };

    const getAccessTokenResult = extendLoginMethod('getAccessTokenResult', _getAccessTokenResult);

    /**
     * Firebase callbacks
     */

    const handleAuthStateChanged = async (user: User | null) => {
        if (!ready) {
            console.log(user);
            try {
                onInitialise && (await onInitialise(accessor));
            } catch (error) {
                console.error(error);
            }
            dispatch(AuthActions.setReady(true));
        }
    };

    const handleIdTokenChanged = async (user: User | null) => {
        onIdTokenChanged && (await onIdTokenChanged(accessor, user));
    };

    /**
     * Other
     */

    const accessor = {
        navigate,
        dispatch,
        authenticated,
        authenticatedChecked,
        getAuthenticated,
        loadAuthenticated,
        getAuthCallback,
    };

    const wrappers = {
        getRedirectResult,
        signInWithRedirect,
        signInWithCustomToken,
        signInWithCredential,
        signinWithPopup,
        signInWithEmailAndPassword,
        signOut,
    };

    const callbacks = {
        handleAuthStateChanged,
        handleIdTokenChanged,
    };

    return {
        accessor,
        isLoading,
        authenticated,
        authenticatedChecked,
        beforeInitialise,
        loadAuthenticated,
        getAuthCallback,
        refreshIdToken,
        autoDetectSigninWith,
        signinWithGoogleCustomRedirect,
        signinWithFacebookCustomRedirect,
        signinWithAppleCustomRedirect,
        signinWithAccessTokenResult,
        getAccessTokenResult,
        ...wrappers,
        ...callbacks,
    };
};

export type authHandler = ReturnType<typeof useAuth>;

type Accessor = authHandler['accessor'];
