import { Button, Card, CardBody, CardHeader, FormControl, FormLabel, Heading, Input, Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { CommunityAccountApi } from '../../community-api/accounts/api';

export const AccountLinkTest: FC = () => {
    const [email, setEmail] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<string>();

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!email) {
            return;
        }
        setSearchLoading(true);
        try {
            const res = await CommunityAccountApi.PostAccountLink({ email });
            setSearchResults(JSON.stringify(res.data, null, 2));
            console.log(res);
        } catch (error) {}
        setSearchLoading(false);
    };

    const onCodeSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (!code) {
            return;
        }
        setSearchLoading(true);
        try {
            const res = await CommunityAccountApi.PostAccountLinkProcess({ code });
            setSearchResults(JSON.stringify(res.data, null, 2));
            console.log(res);
        } catch (error) {}
        setSearchLoading(false);
    };

    return (
        <Stack spacing="1">
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Link account
                    </Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={onSubmit}>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    type="input"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>

                            <Stack direction="row" spacing={1}>
                                <Button type="submit" isLoading={searchLoading}>
                                    Link
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Process
                    </Heading>
                </CardHeader>
                <CardBody>
                    <form onSubmit={onCodeSubmit}>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>Code</FormLabel>
                                <Input
                                    name="code"
                                    type="input"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </FormControl>

                            <Stack direction="row" spacing={1}>
                                <Button type="submit" isLoading={searchLoading}>
                                    Submit
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <Heading size="sm" fontWeight={700}>
                        Link result
                    </Heading>
                </CardHeader>
                <CardBody>{searchResults}</CardBody>
            </Card>
        </Stack>
    );
};
