import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRosterfyTranslation } from './resources';
// import { addTranslationNamespace } from './utils';

export const useRosterfyTranslation = () => {
    const terms = {} as Record<string, string>;
    // const { terms } = useDataVmsDto();
    const { t: translate, i18n } = useTranslation();

    const provideTranslation = (msg: string) => {
        return translate(msg);
    };
    // const provideTranslation = (msg: string) => {
    //     return msg;
    // }

    const getTerms = () => {
        return terms ? terms : ({} as Record<string, string>);
    };

    const t = (msg: string, termOverrides?: Record<string, string>) => {
        const message = provideTranslation(msg);
        const _terms = { ...getTerms(), ...termOverrides } as Record<string, string>;
        for (let key in _terms) {
            message.replace(new RegExp('{' + key + '}', 'g'), _terms[key]);
        }
        return message;
    };

    const addResources = (resources: Record<string, any>) => {
        Object.keys(resources).forEach((lang) => {
            const namespaces = resources[lang];
            Object.keys(namespaces).forEach((ns) => {
                i18n.addResourceBundle(lang, ns, namespaces[ns]);
            });
        });
    };

    // const addResourcesViaTranslationNamespace = (resources: Record<string, any>) => {
    //     Object.keys(resources).forEach((lang) => {
    //         i18n.addResourceBundle(lang, 'translation', resources[lang].translation);
    //     });
    // };

    useEffect(() => {
        getRosterfyTranslation()
            .then((resources) => {
                // const translationResources = addTranslationNamespace(resources);
                // addResourcesViaTranslationNamespace(translationResources);
                addResources(resources);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return { t, changeLanguage: i18n.changeLanguage };
};
