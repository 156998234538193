import { ThemeConfig, extendBaseTheme } from '@chakra-ui/react';
import chakraTheme from '@chakra-ui/theme';

const {
    Button,
    Heading,
    Input,
    Card,
    Spinner,
    Switch,
    Form,
    FormError,
    FormLabel,
    Radio,
    Modal,
    List,
    Textarea,
    Checkbox,
    Alert,
} = chakraTheme.components;

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};

export const theme = extendBaseTheme({
    config: config,
    fonts: {
        heading: `'Raleway', sans-serif`,
        body: `'Raleway', sans-serif`,
    },
    components: {
        Button,
        Heading,
        Input,
        Card,
        Spinner,
        Switch,
        Form,
        FormError,
        FormLabel,
        Radio,
        Modal,
        List,
        Textarea,
        Checkbox,
        Alert,
    },
    colors: {
        blue: {
            '50': '#EBEBFA',
            '100': '#C6C6F1',
            '200': '#A1A1E8',
            '300': '#7C7CDF',
            '400': '#5757D6',
            '500': '#3232CD',
            '600': '#2828A4',
            '700': '#1E1E7B',
            '800': '#141452',
            '900': '#0A0A29',
        },
    },
});
