import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { CustomFirebaseUiActions } from './reducer';
import { useAppDispatch } from '../../hooks';
import { ProviderEmailButtonMixin } from '../../styles/mixins-button';

export const EmailLoginButton: FC = () => {
    const dispatch = useAppDispatch();

    const onClick = () => {
        dispatch(CustomFirebaseUiActions.setView('login_email'));
    };
    return (
        <Button onClick={onClick} leftIcon={<FaEnvelope size="1.125rem" />} {...ProviderEmailButtonMixin}>
            Continue with email
        </Button>
    );
};
