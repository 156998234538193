import { useLocation } from 'react-router-dom';
import { RedirectState } from './useAccessTokenHandler';
import { AppEnv } from '../../config';

export type FacebookCustomLoginProps = {};

export const facebookCustomLogin = (props: FacebookCustomLoginProps) => {
    const {} = props;
    const location = useLocation();

    const buildStartUrl = (state: RedirectState) => {
        const params = {
            client_id: AppEnv.FACEBOOK_APP_ID,
            redirect_uri: window.location.origin,
            state: encodeURIComponent(JSON.stringify(state)),
            scope: 'email,public_profile',
            response_type: 'token',
        } as const;
        return (
            `https://www.facebook.com/v18.0/dialog/oauth?` +
            Object.keys(params)
                .map((key) => `${key}=${params[key as keyof typeof params]}`)
                .join('&')
        );
    };

    const start = async () => {
        const state: RedirectState = {
            type: 'facebook',
            ct: 'testing1234',
            status: 'request',
            pathOverride: location.pathname,
            searchOverride: location.search,
        };
        const url = buildStartUrl(state);
        window.location.href = url;
        return null;
    };

    return {
        start,
    };
};

export type GoogleCustomLoginProps = {};

export const googleCustomLogin = (props: GoogleCustomLoginProps) => {
    const {} = props;
    const location = useLocation();

    const buildStartUrl = (state: RedirectState) => {
        const params = {
            client_id: AppEnv.FIREBASE_PROVIDER_GOOGLE_CLIENTID,
            redirect_uri: window.location.origin,
            state: encodeURIComponent(JSON.stringify(state)),
            scope: 'openid https://www.googleapis.com/auth/userinfo.email profile',
            prompt: 'select_account',
            response_type: 'token',
        } as const;
        return (
            `https://accounts.google.com/o/oauth2/v2/auth?` +
            Object.keys(params)
                .map((key) => `${key}=${params[key as keyof typeof params]}`)
                .join('&')
        );
    };

    const start = async () => {
        const state: RedirectState = {
            type: 'google',
            ct: 'testing1234',
            status: 'request',
            pathOverride: location.pathname,
            searchOverride: location.search,
        };
        const url = buildStartUrl(state);
        window.location.href = url;
        return null;
    };

    return {
        start,
    };
};

export type AppleCustomLoginProps = {};

export const appleCustomLogin = (props: AppleCustomLoginProps) => {
    const {} = props;
    const location = useLocation();

    const buildStartUrl = (state: RedirectState) => {
        const params = {
            response_type: 'code id_token',
            client_id: AppEnv.APPLE_CLIENT_ID,
            redirect_uri: AppEnv.APPLE_REDIRECT_URI ? AppEnv.APPLE_REDIRECT_URI : window.location.origin,
            state: encodeURIComponent(JSON.stringify(state)),
            scope: 'name email',
            response_mode: 'form_post',
            context_uri: window.location.origin,
        } as const;
        return (
            `https://appleid.apple.com/auth/authorize?` +
            Object.keys(params)
                .map((key) => `${key}=${params[key as keyof typeof params]}`)
                .join('&')
        );
    };

    const start = async () => {
        const state: RedirectState = {
            type: 'apple',
            ct: 'testing1234',
            status: 'request',
            pathOverride: location.pathname,
            searchOverride: location.search,
        };
        const url = buildStartUrl(state);
        window.location.href = url;
        return null;
    };

    return {
        start,
    };
};
