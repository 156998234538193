import { useState } from 'react';

export const useNotificationsPagination = () => {
    const [indexHistory, setIndexHistory] = useState<(string | undefined)[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const prevIndex = indexHistory[pageIndex - 1];
    const currIndex = indexHistory[pageIndex];
    const nextIndex = indexHistory[pageIndex + 1];
    const isLast = indexHistory.length === 0 || pageIndex === indexHistory.length - 1;

    const nextPage = () => {
        if (!nextIndex) {
            return;
        }
        setPageIndex(pageIndex + 1);
    };

    const prevPage = () => {
        if (!prevIndex) {
            return;
        }
        setPageIndex(pageIndex - 1);
    };

    const appendHistory = (index: string | undefined) => {
        setIndexHistory([...indexHistory, index]);
    };

    return {
        nextPage,
        prevPage,
        appendHistory,
        prevIndex,
        currIndex,
        nextIndex,
        isLast,
    };
};
