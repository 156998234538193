import { CommunityAuthApi } from '../community-api/auth/api';

export const OtlcService = () => {
    const requestEmail = async (email: string) => {
        try {
            const res = await CommunityAuthApi.LoginEmail({
                email,
            });
            return res.data;
        } catch (err) {
            console.error(err);
        }
        return undefined;
    };

    const getCustomToken = async (code: string) => {
        try {
            const res = await CommunityAuthApi.CustomToken({
                code,
            });
            const { customToken } = res.data;
            return customToken;
        } catch (err) {
            console.error(err);
        }
        return undefined;
    };
    return {
        getCustomToken,
        requestEmail,
    };
};

export type OtlcService = ReturnType<typeof OtlcService>;
